import React, { useState } from 'react';
import { ReactTerminal } from "react-terminal";
import FsLightbox from 'fslightbox-react';
import baby from './img/babymyyryli.png';

function App() {
  const [toggler, setToggler] = useState(false);
  const commands = {

    apua: <>
      <br/>
      <strong>Saatavilla olevat komennot</strong> <br />
      &nbsp;&nbsp;▪ apua -- Näyttää kaikki komennot<br />
      &nbsp;&nbsp;▪ kirjanmerkit -- Näyttää kirjanmerkit<br />
      &nbsp;&nbsp;▪ ankka -- ???<br />
      &nbsp;&nbsp;▪ windows -- Käynnistää Windowsin<br />
      &nbsp;&nbsp;▪ kuka -- Myyryli kuka? <br />
      &nbsp;&nbsp;▪ media -- Party hard <br />
      <br/>
    </>,
    kirjanmerkit:
      <>
      <br />
        <strong>Laavamyyryli network:</strong> <br />
        &nbsp;&nbsp;▪ <a href='https://place.laavamyyryli.fi' target='_blank' rel="noreferrer">place.laavamyyryli.fi</a> <br />
        &nbsp;&nbsp;▪ <a href='https://himmleri2077.zurial.fi' target='_blank' rel="noreferrer">himmleri2077.zurial.fi</a> <br />
        &nbsp;&nbsp;▪ <a href='https://angelika.zurial.fi' target='_blank' rel="noreferrer">angelika.zurial.fi</a> <br />
        &nbsp;&nbsp;▪ <a href='https://zurial.fi' target='_blank' rel="noreferrer">zurial.fi</a> <br />
        &nbsp;&nbsp;▪ <a href='https://paikkatietokanta.fi' target='_blank' rel="noreferrer">paikkatietokanta.fi</a> <br />
        &nbsp;&nbsp;▪ <a href='https://valontuoja.fi' target='_blank' rel="noreferrer">valontuoja.fi</a> <br />
        &nbsp;&nbsp;▪ <a href='https://projektit.pro' target='_blank' rel="noreferrer">projektit.pro</a> <br />
        &nbsp;&nbsp;▪ <a href='https://penttinen.fi' target='_blank' rel="noreferrer">penttinen.fi</a> <br />
        <br />
      </>,

    ankka:
      <>
      <br />
        🦆 Kwak, sanoi ankka.
       <br />
      </>,

    windows: () => setToggler(!toggler),

    kuka: <>
    <br />
      <img src={baby} alt="Baby" />
      <br />
    </>,

    media: <>
    <br />
    <iframe width="560" height="315" src="https://www.youtube.com/embed/16G-Hv23nqw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <br />
    </>,

    exit: 
    <>
      M̶̨͔̲͈̂̈̈́̈́̎̒̌̀̕͝ù̷̹̪̈į̸̞̺͉̗͎̬̰̱̱̀͐ŝ̸̢͉͐̎͒͋̈́t̴̢̨̘͇̯̩̲̗̘̲̓̔̈́͑̓̑̂́͝͝ǎ̴̮͉̏̌̓̓͐̑̆̚ ̶̠͈̲̏͗s̶̡͔̯͖͛̆̔̇y̷̛̖̲̓ö̴̹͌̃͆̌̐͘d̷̝͓̪̘̣͔̪̕ä̴̹̳̟̹̹̪͆̍̀́͗̐<br />  ̸̰̫͕̠̫̰͚̟͋̆ͅv̶͕͙͙̤̙͔͈̚͜i̶͈̳̣̤̺͔͒̀͛͑̀͜h̴̪̥̗̬̮͑a̶̙̘͈͇̩̝̿̓̏͋n̷̪̭͈̞͆͋̆̓̈̓̄̾͘͘n̵͕͎̖͕̙̦̈́͂̔͂͊͜ḛ̶̭́̂̂̐̑͛̑̿ḱ̶̢̝̰̱͚͎̳̠̗̠̏̑̾̐͠s̷͇͎̘̭̄̀͐͠ͅę̸̡͉̣̮͝t̷̡̨̜̩̣͕̮̤͈̗͗́̔͊
    </>,

    quit:
    <>
    M̶̨͔̲͈̂̈̈́̈́̎̒̌̀̕͝ù̷̹̪̈į̸̞̺͉̗͎̬̰̱̱̀͐ŝ̸̢͉͐̎͒͋̈́t̴̢̨̘͇̯̩̲̗̘̲̓̔̈́͑̓̑̂́͝͝ǎ̴̮͉̏̌̓̓͐̑̆̚ ̶̠͈̲̏͗s̶̡͔̯͖͛̆̔̇y̷̛̖̲̓ö̴̹͌̃͆̌̐͘d̷̝͓̪̘̣͔̪̕ä̴̹̳̟̹̹̪͆̍̀́͗̐<br />  ̸̰̫͕̠̫̰͚̟͋̆ͅv̶͕͙͙̤̙͔͈̚͜i̶͈̳̣̤̺͔͒̀͛͑̀͜h̴̪̥̗̬̮͑a̶̙̘͈͇̩̝̿̓̏͋n̷̪̭͈̞͆͋̆̓̈̓̄̾͘͘n̵͕͎̖͕̙̦̈́͂̔͂͊͜ḛ̶̭́̂̂̐̑͛̑̿ḱ̶̢̝̰̱͚͎̳̠̗̠̏̑̾̐͠s̷͇͎̘̭̄̀͐͠ͅę̸̡͉̣̮͝t̷̡̨̜̩̣͕̮̤͈̗͗́̔͊
    </>,
  };

  return (
    <div className="App">

        <FsLightbox
          toggler={toggler}
          sources={[
            <iframe src="https://www.retrogames.cz/js-dos-622start.php?id2=1429" title="Windows 3.11"
            className="iframe" width="800px" height="600px" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
          ]}
          />

      <div id="crt">
        <div className="scanline"></div>
        <div className="terminal"></div>
        <ReactTerminal
          commands={commands}
          welcomeMessage={<span id='welcome-message'>
            <span>Laavamyyryleiden purkki v.0.0.3</span>
            <span className="small">Aloita kirjoittamalla apua ja paina palautusnäppäintä.</span>
          </span>}
          errorMessage="Komentoa ei löydy! (ノಠ益ಠ)ノ彡┻━┻"
          showControlBar={false}
          showControlButtons={false}
          prompt=">"
          themes={{
            laavamyyryli: {
              themeBGColor: "#000000",
              themeToolbarColor: "#000000",
              themeColor: "#FFBF00",
              themePromptColor: "#FFBF00"
            }
          }}
          theme="laavamyyryli"
        />
      </div>
    </div>
  );
}
export default App;